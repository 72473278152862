/*Webkit 滚动条*/
::-webkit-scrollbar{
    width: .5rem;
    height: .5rem;
}
::-webkit-scrollbar-thumb{
    background-color: var(--c-grey--2);
    border-radius: .75rem;
    border: .5rem solid transparent;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--c-grey);
}
::-webkit-scrollbar-thumb:active{
    background-color: var(--c-grey--1);
}
::-webkit-scrollbar-track{
    background-color: var(--c-grey--3);
}
::-webkit-scrollbar-corner{
    height: 0;
    width: 0;
    background-color: transparent;
}
::-webkit-scrollbar-button{
    height: 0;
    width: 0;
    background-color: transparent;
}
/*Firefox 滚动条*/
*{
    scrollbar-color: var(--c-grey--2) transparent;
    scrollbar-gutter: auto;
    scrollbar-width: thin;
}