/*:is(.ant-picker-dropdown.ant-picker-dropdown.ant-picker-dropdown, .ant-picker.ant-picker){
    pointer-events: all;
}*/
.ant-pro-card-body.ant-pro-card-body{
    padding-left: 0;
    padding-right: 0;
}
.ant-pro-card{
    height: 100%;
}
.ant-modal{
    padding-bottom: 5rem;
}
.ant-modal-content.ant-modal-content{
    padding: .8rem 1.5rem 1.5rem;
}
.openid .ant-typography{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
:is(.phone, .email) .ant-typography{
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active{
    transition: background-color 129140000s ease-in-out 0s;
    -webkit-text-fill-color: var(--c-base) !important;
}