*{
    outline: none;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    transition: background-color .1s;
}
::selection{
    background-color: var(--c-selection);
}
::-moz-selection{
    background-color: var(--c-selection);
}
noscript{
    display: grid;
    place-items: center;
}
/*正常化浏览器行为*/
*[contenteditable]:not([contenteditable="false"]){
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write-plaintext-only;
    /*user-modify: read-write-plaintext-only;*/
}
*[contenteditable]:not([contenteditable="false"]) *{
    white-space: pre-wrap;
    word-wrap: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}
body{
    margin: 0;
    padding: 0;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    background-color: var(--c-grey--4);
    color: var(--c-base);
}
#root{
    display: block;
    height: 100vh;/*兼容*/
    height: 100dvh;
    width: 100vw;/*兼容*/
    width: 100dvw;
    overflow: clip;
}