:root{
    --c-base: #FFFFFFCF;
    --c-taskbar: beige;
    --c-menu: honeydew;
    --c-menu-1: antiquewhite;
    --c-menu-2: beige;
    --c-link: #4694ec;
    --c-link-d: #3b82d7;
    --c-link-tra: #B7B0DFB9;
    --c-active: olive;
    --c-boxshadow: skyblue;
    --c-focus: dodgerblue;
    --c-border: #5E87C9;
    --border: solid .0625rem var(--c-border);
    --c-main: #2383E2;
    --c-warning :#F9DF82;
    --c-danger: #FF416C;
    --c-success: #27CE7F;
    --c-selection: #234266;
    --c-text-menu: #191711;
    --c-text-main: #242322;
    --c-text-nav: black;
    --c-black-tra: #2423220F;
    --c-black-tra-1: #24232219;
    --c-black-tra-2: #24232223;
    --c-black-tra-3: #2423224A;
    --c-black-tra-4: #24232259;
    --c-black-tra-5: #2423226E;
    --c-black-tra-6: #2423228E;
    --c-black-tra-7: #242322A5;
    --c-1: #CEF6E3;
    --c-2: #17F18B;
    --c-3: #FFE8CF;
    --c-3-a: #FFECCC;
    --c-4: #FDFDC4;
    --c-4-a: #F4F3CC;
    --c-5: #CCFFEC;
    --c-5-a: #C9FCE9;
    --c-6: #FFD3D3;
    --c-6-a: #FFE4E4;
    --c-black: black;
    --c-grey--5: #0E0E0E;
    --c-grey--4: #191919;
    --c-grey--3: #202323;
    --c-grey--2-5: #2C2C2C;
    --c-grey--2-75: #3D3D3D;
    --c-grey--2: #444444;
    --c-grey--1: #4C4C4C;
    --c-grey: #505050;
    --c-grey-1: #989898;
    --c-grey-1-5: #9D9D9D;
    --c-grey-2: #A9A9A9;
    --c-grey-3: #AEAEAE;
    --c-grey-4: #BABABA;
    --c-grey-4-5: #CBCBCB;
    --c-grey-5: lightgrey;
    --c-grey-6: #DADADA;
    --c-grey-7: #EAEAEA;
    --c-grey-8: #EFEFEF;
    --c-overlay: #2228;
}